<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="订单号" prop="dingDanHao" >
                <el-input v-model="form.dingDanHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品名称" prop="shangPinMC" >
                <el-input v-model="form.shangPinMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品分类" prop="shangPinFenLei" >
                <el-input v-model="form.shangPinFenLei" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品分类名称" prop="shangPinFenLeiMC" >
                <el-input v-model="form.shangPinFenLeiMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="货号" prop="huoHao" >
                <el-input v-model="form.huoHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="条码" prop="tiaoMa" >
                <el-input v-model="form.tiaoMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="单位" prop="danWei" >
                <el-input v-model="form.danWei" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="进价" prop="jinJia" >
                <el-input v-model="form.jinJia" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="原单价" prop="yuanDanJia" >
                <el-input v-model="form.yuanDanJia" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="数量" prop="shuLiang" >
                <el-input v-model="form.shuLiang" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="收银员打折金额" prop="shouYinYuanDaZheJinE" >
                <el-input v-model="form.shouYinYuanDaZheJinE" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="积分抵扣金额" prop="jiFenDiKouJinE" >
                <el-input v-model="form.jiFenDiKouJinE" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="抹零优惠金额" prop="moLingYouHuiJinE" >
                <el-input v-model="form.moLingYouHuiJinE" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="实售总价" prop="shiShouZongJia" >
                <el-input v-model="form.shiShouZongJia" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="使用积分" prop="shiYongJiFen" >
                <el-input v-model="form.shiYongJiFen" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="订单类型" prop="dingDanLX" >
                <el-input v-model="form.dingDanLX" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="退货" prop="tuiHuo" >
                <el-input v-model="form.tuiHuo" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="交易时间" prop="jiaoYiSJ">
                <el-date-picker v-model="form.jiaoYiSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/jygl/ShangPinXiaoShouMX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                dingDanHao: [], // 订单号
                shangPinMC: [], // 商品名称
                shangPinFenLei: [], // 商品分类
                shangPinFenLeiMC: [], // 商品分类名称
                huoHao: [], // 货号
                tiaoMa: [], // 条码
                danWei: [], // 单位
                jinJia: [], // 进价
                yuanDanJia: [], // 原单价
                shuLiang: [], // 数量
                shouYinYuanDaZheJinE: [], // 收银员打折金额
                jiFenDiKouJinE: [], // 积分抵扣金额
                moLingYouHuiJinE: [], // 抹零优惠金额
                shiShouZongJia: [], // 实售总价
                shiYongJiFen: [], // 使用积分
                dingDanLX: [], // 订单类型
                tuiHuo: [], // 退货
                jiaoYiSJ: [], // 交易时间
            }
            this.titlePrefix = '商品销售明细';
            this.defaultForm = {
                id: null,
                dingDanHao: "", // 订单号
                shangPinMC: "", // 商品名称
                shangPinFenLei: "", // 商品分类
                shangPinFenLeiMC: "", // 商品分类名称
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                danWei: "", // 单位
                jinJia: "", // 进价
                yuanDanJia: "", // 原单价
                shuLiang: "", // 数量
                shouYinYuanDaZheJinE: "", // 收银员打折金额
                jiFenDiKouJinE: "", // 积分抵扣金额
                moLingYouHuiJinE: "", // 抹零优惠金额
                shiShouZongJia: "", // 实售总价
                shiYongJiFen: "", // 使用积分
                dingDanLX: "", // 订单类型
                tuiHuo: "", // 退货
                jiaoYiSJ: "", // 交易时间
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>