<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="商品" textWidth="220px">
                <el-input v-model="searchValue" size="small" @change="inputChange">
                    <x-selector-one @change="selectChange" slot="prepend" v-model="searchCondition"
                                    style="width: 100px;" size="small" dictType="T_SELECT_CONDITION"/>
                </el-input>
            </x-search-item>
            <x-search-item label="商品分类">
                <el-cascader :show-all-levels="false" size="small" style="width: 100%" v-model="search.shangPinFenLei"
                             :options="fenLeiOptions"
                             :props="fenLeiProps">
                </el-cascader>
            </x-search-item>
            <x-search-item label="交易时间" textWidth="220px" hasEnd>
                <el-date-picker v-model="search.jiaoYiSJBegin" size="small" type="datetime"/>
                <el-date-picker v-model="search.jiaoYiSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                <excel-export :tableData="exportTableData" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                              fileName="商品销售明细">
                    <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                </excel-export>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="dingDanHao" label="订单号"/>
            <el-table-column width="180" prop="jiaoYiSJ" label="交易时间"/>
            <el-table-column width="180" prop="shangPinMC" label="商品名称"/>
            <el-table-column width="180" prop="shangPinFenLeiMC" label="商品分类"/>
            <el-table-column width="180" prop="huoHao" label="货号"/>
            <el-table-column width="180" prop="tiaoMa" label="条码"/>
            <el-table-column width="180" prop="danWei" label="单位"/>
            <el-table-column width="180" prop="jinJia" label="进价"/>
            <el-table-column width="180" prop="yuanDanJia" label="原单价"/>
            <el-table-column width="180" prop="shuLiang" label="数量"/>
            <el-table-column width="180" prop="jiFenDiKouJinE" label="积分抵扣金额"/>
            <el-table-column width="180" prop="moLingYouHuiJinE" label="抹零优惠金额"/>
            <el-table-column width="180" prop="shiShouZongJia" label="实售总价"/>
            <el-table-column width="180" prop="shiYongJiFen" label="使用积分"/>
            <el-table-column width="180" label="订单类型">
                <x-dict-show slot-scope="{row}" :code="row.dingDanLX" dictType="T_ORDERS"/>
            </el-table-column>
            <el-table-column width="180" prop="tuiHuo" label="退货">
                <template slot-scope="{ row, $index }">
                    <span v-if="row.tuiHuo">{{row.tuiHuo}}</span>
                    <span v-else>无</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
    </div>
</template>
<script>
    import * as service from "@/service/jygl/ShangPinXiaoShouMX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/jygl/skmx/ShangPinXiaoShouMXEdit";
    import {DICT_TYPE_SP_SEARCH_CONDITION, KONG_ZHI_XIAN_SHI} from '@/util/constant'
    import {mainTree} from "@/service/spgl/ShangPinFenLei"
    import {deepCopy, deepMerge} from "@/util/objects";
    import {getDictType, initDictByType} from "@/util/dict";

    export default {
        name: "ShangPinXiaoShouMXList",
        mixins: [XTableBase],
        components: {Edit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    huoHao: '',
                    tiaoMa: '',
                    shangPinMC: '',
                    shangPinFenLei: '',
                    jiaoYiSJBegin: '',
                    jiaoYiSJEnd: '',
                },
                searchCondition: DICT_TYPE_SP_SEARCH_CONDITION.HH.key,
                searchValue: "",
                headAndKey: {
                    dingDanHao: "订单号",
                    shangPinMC: "商品名称",
                    shangPinFenLeiMC: "商品分类",
                    huoHao: "货号",
                    tiaoMa: "条码",
                    danWei: "单位",
                    jinJia: "进价",
                    yuanDanJia: "原单价",
                    shuLiang: "数量",
                    jiFenDiKouJinE: "积分抵扣金额",
                    moLingYouHuiJinE: "抹零优惠金额",
                    shiShouZongJia: "实售总价",
                    shiYongJiFen: "使用积分",
                    dingDanLX: "订单类型",
                    tuiHuo: "退货",
                    jiaoYiSJ: "交易时间",
                },
                ordersDict: getDictType("T_ORDERS"),
                condition: [],
                exportTableData: [],
                fenLeiOptions: [],
                fenLeiProps: {
                    emitPath: false,
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly: false,
                    multiple: false
                },
            }
        },
        created() {
            this.getFenLeiOptions()
            initDictByType('T_ORDERS')
        },
        methods: {
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    if (this.table.data) {
                        this.exportTableData = deepCopy(this.table.data)
                        this.exportTableData.forEach(item => {
                            let orders = this.ordersDict.find(element => element.key === item.dingDanLX)
                            item.dingDanLX = orders.value
                            if (!item.tuiHuo) {
                                item.tuiHuo = KONG_ZHI_XIAN_SHI.WU.name
                            }
                        })
                    }
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            inputChange(val) {
                if (this.searchCondition === DICT_TYPE_SP_SEARCH_CONDITION.HH.key) {
                    this.search.huoHao = val
                } else if (this.searchCondition === DICT_TYPE_SP_SEARCH_CONDITION.TM.key) {
                    this.search.tiaoMa = val
                } else {
                    this.search.shangPinMC = val
                }
            },
            selectChange(val) {
                this.search.huoHao = ''
                this.search.tiaoMa = ''
                this.search.shangPinMC = ''
                this.searchValue = ''
            },
            handleClear() { //默认清空方法
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.searchValue = ''
            },
            //获取分类
            getFenLeiOptions() {
                mainTree().then((response) => {
                    this.fenLeiOptions = response.data
                })
            },
        }
    }
</script>

<style scoped>
</style>
